.forbiddenIcon {
    height: 40vmin;
    pointer-events: none;
}

.swing {
    animation: swing 3s infinite ease-in-out 0s;
}

@keyframes swing {
    0% {
        transform: rotate(3deg);
    }
    50% {
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(3deg);
    }
}