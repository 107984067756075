.shake {
    animation: shake 0.2s ease-in-out 0s 2;
}

@keyframes shake {
    0% {
        margin-left: 0rem;
    }
    25% {
        margin-left: 0.5rem;
    }
    75% {
        margin-left: -0.5rem;
    }
    100% {
        margin-left: 0rem;
    }
}